import React from 'react';

export function If(props: IfProps): React.ReactElement {
    if (props.if) {
        return <>{props.then?.()}</>;
    } else {
        return <></>;
    }
}

export interface IfProps {
    if?: boolean;
    then?: () => React.ReactElement | React.ReactFragment;
}
