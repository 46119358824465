import { CWUser } from 'cw-isdk/core';
import React, { useEffect, useState } from 'react';
import { catchError, of, tap } from 'rxjs';
import Container from 'typedi';
import { useRefStateObject } from '../../global-hooks/use-ref-state-object.hook';
import { useSymbol } from '../../global-hooks/use-symbol.hook';
import { LoggingService } from '../../logging.service';
import { Urls } from '../../urls';
import { LogInData, LoginError } from './log-in.data';

const logger = Container.get(LoggingService);

export function useLogIn(onLoggedIn: (user: CWUser) => void ): UseLogInState {

    const [state, setState] = useRefStateObject<UseLogInState>({
        errorMessage: undefined,
        loading: false,
        logIn: (userName: string, password: string) => {
            setUserName(userName);
            setPassword(password);
            nextDoLogIn();
        }
    });

    const [userName, setUserName] = useState<string | undefined>();
    const [password, setPassword] = useState<string | undefined>();
    const [doLogIn, nextDoLogIn] = useSymbol();

    useEffect(() => {

        if (doLogIn == null) { return; }
        if (userName == null || userName.length < 1) {
            return;
        }
        if (password == null || password.length < 1) {
            return;
        }

        const loginData = Container.get(LogInData);
        setState({ errorMessage: undefined, loading: true });
        const subscription = loginData.credentialLogin(userName, password).pipe(
            tap(loginResponse => {
                setState({ loading: false });

                if (loginResponse.Error != null) {
                    let errorMessage: React.ReactElement | undefined;
                    switch (loginResponse.Error) {
                        case LoginError.RequiresReset:
                            errorMessage= (
                                <div>
                                    Your password must be reset before you can log in.
                                    <a href={Urls.passwordResetPage}>Reset password</a>
                                </div>);
                            break;
                        case LoginError.InvalidUserNameOrPassword:
                            errorMessage= <div>Password/Username is invalid</div>;
                            break;
                        case LoginError.Unknown:
                            errorMessage= <div>Unknown error</div>;
                            break;
                        default:
                            errorMessage = <div>Unknown error</div>;
                            break;
                    }
                    setState({ errorMessage });
                } else if (loginResponse.LoggedIn && loginResponse.User != null) {
                    onLoggedIn(loginResponse.User);
                } else {
                    setState({ errorMessage: (<div>Unknown error</div>) });
                }
            }),
            catchError(error => {
                logger.error(error);
                setState({ errorMessage: (<div>Unknown error</div>), loading: false });
                return of();
            })
        ).subscribe();

        return () => {
            setState({ loading: false });
            subscription.unsubscribe();
        };

    }, [doLogIn]);

    return state.current;
}

export interface UseLogInState {
    errorMessage: React.ReactElement | undefined;
    loading: boolean;
    logIn: (userName: string, password: string) => void;
}
