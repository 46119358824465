import React, { useCallback, useRef, useState } from 'react';

/**
 * Similar to `useState` {@link https://reactjs.org/docs/hooks-state.html}
 * except it takes an object for a state and the update
 * function accepts a partial state object.
 */
export function useRefStateObject<TState>(initialState: TState): [ React.MutableRefObject<TState>, (newState: Partial<TState>) => void] {

    const stateRef = useRef<TState>({ ...initialState });
    const [, setState] = useState(initialState);
    const setter = useCallback((newState: Partial<TState>) => {
        const updatedState = { ...stateRef.current, ...newState };
        stateRef.current = Object.freeze(updatedState);
        setState(updatedState);
    }, [stateRef]);

    return [
        stateRef,
        setter
    ];
}
