import { useEffect } from 'react';
import { catchError, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import Container from 'typedi';
import { useRefStateObject } from '../../global-hooks/use-ref-state-object.hook';
import { useSymbol } from '../../global-hooks/use-symbol.hook';
import { LoggingService } from '../../logging.service';
import { LogInData } from './log-in.data';

const logger = Container.get(LoggingService);

export function useHideDefaultLogin(): UseHideDefaultLoginState {

    const [state, setState] = useRefStateObject({
        loading: false,
        hideDefaultLogin: false,
        reload: () => nextReloadSymbol()
    });

    const [reloadSymbol, nextReloadSymbol] = useSymbol(Symbol());

    useEffect(() => {

        setState({ loading: true });

        const loginData = Container.get(LogInData);
        const subscription = loginData.hideDefaultLogin().pipe(
            tap(hideLogin => {
                setState({ hideDefaultLogin: hideLogin, loading: false });
            }),
            catchError(error => {
                // TODO
                logger.error(error);
                setState({ hideDefaultLogin: false, loading: false });
                return of();
            })
        ).subscribe();

        return () => {
            setState({ loading: false, hideDefaultLogin: false });
            subscription.unsubscribe();
        };

    }, [reloadSymbol]);

    return state.current;
}

export interface UseHideDefaultLoginState {
    hideDefaultLogin: boolean;
    loading: boolean;
    reload: () => void;
}
