import { ServiceTypes } from 'cw-isdk/core/service-types';

export class CoreResponseError extends Error {

    public override readonly name = 'CoreResponseError';

    public constructor(
        public coreResponse: ServiceTypes.CoreResponseBase_<unknown> | undefined,
        public innerError: Error | undefined,
        public response: Response
    ) {
        super();
    }
}
