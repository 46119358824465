import { useState } from 'react';

/**
 * This hook provides a simple way to get a unique symbol on every call.
 * It is equivalent to:
 * const [mySymbol, setMySymbol] = use<symbol | undefined>(initialValue);
 *
 * It is useful for imperatively triggering effects.
 * @example
 * ```ts
 * const [next, setNext] = useSymbol();
 * useEffect(() => {
 *  if (next == null) return;
 *  // do work
 * }, [next])
 *
 * setTimeout(() => setNext(), 100);
 * ```
 */
export function useSymbol(initialValue?: symbol): [ state: symbol | undefined, next: (clear?: boolean) => void ] {
    const [ state, setState ] = useState<symbol | undefined>(initialValue);

    return [
        state,
        clear => clear ? setState(undefined) : setState(Symbol())
    ];
}
