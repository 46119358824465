import { AuthenticationService } from '@shared/http/authentication.service';
import { CoreResponseError } from '@shared/http/core-response-error';
import { CWUser } from 'cw-isdk/core';
import { useEffect } from 'react';
import { catchError, of, tap } from 'rxjs';
import Container from 'typedi';
import { LoggingService } from '../logging.service';
import { useRefStateObject } from './use-ref-state-object.hook';
import { useSymbol } from './use-symbol.hook';

const logger = Container.get(LoggingService);

export function useCurrentUser(): UseCurrentUserState {

    const  [state, setState] = useRefStateObject<UseCurrentUserState>({
        loading: false,
        user: undefined,
        reload: () => setReloadSymbol()
    });

    const [reloadSymbol, setReloadSymbol] = useSymbol(Symbol());

    useEffect(() => {
        setState({ loading: true });
        const authenticationService = Container.get(AuthenticationService);
        const sub = authenticationService.getUser({}).pipe(
            tap(user => {
                user = user == null || user.EmployeeSid < 1 ? null : user;
                setState({ loading: false, user });
            }),
            catchError(error => {
                if (!(error instanceof CoreResponseError && error.coreResponse?.Status === 3)) {
                    logger.error(error);
                }
                setState({ loading: false, user: undefined });
                return of();
            })
        ).subscribe();

        return () => {
            setState({ loading: false });
            sub.unsubscribe();
        };
    }, [reloadSymbol]);

    return state.current;
}

export interface UseCurrentUserState {
    loading: boolean;
    reload: () => void;
    user: CWUser | undefined;
}
