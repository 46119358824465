import { UrlUtil } from '../url-util';
import { CoreResponseStatus } from 'cw-isdk/core';
import { ServiceTypes } from 'cw-isdk/core/service-types';
import { concatMap, Observable } from 'rxjs';
import { Service } from 'typedi';
import { CoreResponseError } from './core-response-error';
import { HttpService } from './http.service';

@Service()
export class ApiService {

    private readonly _httpService: HttpService;

    public constructor(httpService: HttpService) {
        this._httpService = httpService;
    }

    public get<TData>(responseType: 'httpResponse', options: ApiRequestOptions<TData>): Observable<Response>;
    public get<TData, TResponse>(responseType: 'coreResponse', options: ApiRequestOptions<TData>): Observable<ServiceTypes.CoreResponseBase_<TResponse>>;
    public get<TData, TResponse>(responseType: 'coreResponseValue', options: ApiRequestOptions<TData>): Observable<TResponse>;
    public get<TData, TResponse>(responseType: ApiResponseType, options: ApiRequestOptions<TData>): Observable<TResponse> {

        const url = options.baseUrl == null
            ? UrlUtil.makeStandardApiUrl(options.area, options.service, options.action).toString()
            : UrlUtil.makeStandardApiUrl(options.baseUrl, options.area, options.service, options.action).toString();

        return this._httpService.get(url, 'response', {
            headers: {},
            query: new URLSearchParams({data: JSON.stringify(options.data ?? {})}),
        }).pipe(
            concatMap(response =>
                this.processResponse<TResponse>(response, responseType)
            )
        );
    }

    public post<TData>(responseType: 'httpResponse', options: ApiRequestOptions<TData>): Observable<Response>;
    public post<TData, TResponse>(responseType: 'coreResponse', options: ApiRequestOptions<TData>): Observable<ServiceTypes.CoreResponseBase_<TResponse>>;
    public post<TData, TResponse>(responseType: 'coreResponseValue', options: ApiRequestOptions<TData>): Observable<TResponse>;
    public post<TData, TResponse>(responseType: ApiResponseType, options: ApiRequestOptions<TData>): Observable<TResponse> {

        const data = JSON.stringify(options.data ?? {});
        const url = UrlUtil.makeStandardApiUrl(options.area, options.service, options.action).toString();

        let postObservable: Observable<Response>;
        if (options.contentType === 'json') {
            postObservable = this._httpService.post(url, {
                body: data,
                headers: {
                    'Content-Type': 'application/json'
                }
            });
        } else {
            postObservable = this._httpService.postUrlEncodedBody(url, {
                body: new URLSearchParams({ data }),
                headers: {},
            });
        }

        return postObservable.pipe(
            concatMap(response =>
                this.processResponse<TResponse>(response, responseType)
            )
        );
    }

    private processResponse<TResponse>(response: Response, responseType: ApiResponseType): Observable<TResponse> {

        return new Observable<TResponse>(subscriber => {

            if (responseType === 'httpResponse') {
                subscriber.next(response as unknown as TResponse);
                subscriber.complete();
                return;
            }

            response.json().then(
                (coreResponse: CoreResponse) => {

                    if (response.status !== 200 || coreResponse.Status !== 0 || coreResponse.ErrorMessages.length > 0) {
                        subscriber.error(new CoreResponseError(coreResponse, undefined, response));
                    } else if (responseType === 'coreResponse') {
                        subscriber.next(coreResponse as unknown  as TResponse);
                    } else {
                        subscriber.next(coreResponse.Value as TResponse);
                    }
                    subscriber.complete();
                },
                error => {
                    if (error instanceof DOMException && error.code === DOMException.ABORT_ERR) {
                        subscriber.complete();
                    } else {
                        subscriber.error(new CoreResponseError(undefined, error, response));
                    }
                }
            );
        });
    }
}

export interface CoreResponse {

    ErrorMessages: CoreResponseMessage[];
    Status: CoreResponseStatus;
    SuccessMessages: CoreResponseMessage[];
    Value: unknown;
    WarningMessages: CoreResponseMessage[];

}

export interface CoreResponseMessage {
    Code: number;
    DebugDetails: string;
    DisplayText: string;
    MessageType: number;
    Name: string;
    Service: string;
}

export interface ApiRequestOptions<TData> {
    action: string;
    area: string;
    baseUrl?: URL,
    /**
     * Set to json to send the request
     * object as JSON in the request body with
     * content-type header set to application/json
     */
    contentType?: 'json';
    data?: TData;
    service: string;
    query?: URLSearchParams;
}

/**
 * httpResponse:
 *   Return unchanged Response. Does not throw.
 * coreResponse:
 *   Return the CoreResponse object. Throws if:
 *     1. response status code is not 200
 *     2. response is not CoreResponse
 *     3. CoreResponse.Status != 0
 *     4. CoreResponse.ErrorMessages.length >0
 * value:
 *   Return the CoreResponse.Value object (default) throws
 *   for same reasons as 'coreResponse'
 */
export type ApiResponseType = 'httpResponse' | 'coreResponse' | 'coreResponseValue';
