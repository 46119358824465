import { If } from '@shared/ui/if.component';
import React from 'react';
import Spinner from 'react-bootstrap/Spinner';
import Container from 'typedi';
import { AnonymousShell } from '../../anonymous-shell/anonymous-shell.component';
import { useCurrentUser } from '../../global-hooks/use-current-user.hook';
import { Urls } from '../../urls';
import { LogInActionService } from './log-in-action.service';
import { LogInForm } from './log-in-form.component';
import { useHideDefaultLogin } from './use-hide-default-login.hook';
import { useOauth2Clients } from './use-oauth2-clients.hook';

export function LogIn(): React.ReactElement {

    const { loading: loadingUser, user } = useCurrentUser();
    const { loading: loadingOAuthClients, data } = useOauth2Clients();
    const { loading: loadingHideDefaultLogin, hideDefaultLogin } = useHideDefaultLogin();

    const isLoading = loadingUser || loadingOAuthClients || loadingHideDefaultLogin;
    if (user != null) {
        loggedIn();
        return <></>;
    }

    return (
        <AnonymousShell
            title='Login'
            render={() =>
                <div>
                    <If
                        if={isLoading}
                        then={() => (
                            <Spinner animation='border' variant='primary' />
                        )} />
                    <If
                        if={!isLoading && user == null}
                        then={() => (
                            <LogInForm
                                loggedIn={() => loggedIn()}
                                oauth2={data}
                                hideDefaultLogin={hideDefaultLogin} />
                        )} />
                </div>
            } />
    );
}

function isUrl(urlString: string) {
    try {
        // const r = new RegExp('^(?:[a-z+]+:)?//', 'i');
        // return r.test(urlString);

        return new URL(urlString);
        // return true;
    } catch (_) {
        return undefined;
    }
}

function validateHostName(toString: string) {
    // not url, return original value
    const toUrl = isUrl(toString);
    if (!toUrl) { return toString; }

    // make sure url is on correct domain
    // host includes port number, hostname does not

    // should we treat www.example.com and example.com as equal?
    const windowHostname = window.location.hostname.toLocaleLowerCase().replace('www.', '');
    const urlHostname = toUrl.hostname.toLocaleLowerCase().replace('www.', '');

    if (windowHostname === urlHostname) {
        return toString;
    }

    // to is a url, but not on the same domain
    return '';
}

function loggedIn(): void {
    const navToUrl = validateHostName(getNavigateTo());
    if (navToUrl.length > 0) {
        window.location.href = navToUrl;
    } else {
        const value = Container.get(LogInActionService).getLogInAction();
        if (value === 'selectPlugin') {
            window.location.href = Urls.makeAppSelectionUrl().toString();
        } else {
            // load last app is the default action.
            window.location.href = Urls.makeSwitchAppUrl({ lastApp: true }).toString();
        }
    }
}

function getNavigateTo(): string {
    const url = new URL(window.location.href);
    return url.searchParams.get('to') ?? '';
}
