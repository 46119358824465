import Container, { Service } from 'typedi';
import { CookieService } from '../../cookie.service';
import { LoggingService } from '../../logging.service';

const logger = Container.get(LoggingService);

@Service()
export class LogInActionService {

    private readonly _LogInActionCookieName = 'cw-login-action-selection';

    public constructor(private readonly _cookieService: CookieService) {

    }

    public getLogInAction(): LogInActionType {
        const value = this._cookieService.getCookie(this._LogInActionCookieName, {});
        if (value === 'lastPlugin') {
            return 'lastPlugin';
        } else if (value === 'selectPlugin') {
            return 'selectPlugin';
        } else if (value?.length > 0) {
            logger.error(`Unknown value for ${this._LogInActionCookieName} cookie: ${value}`);
        }

        // Default
        return 'lastPlugin';
    }

    public saveLoginAction(value: LogInActionType): void {
        this._cookieService.setCookie({
            Name: this._LogInActionCookieName,
            Value: value,
            SecondsToLive: Number.MAX_SAFE_INTEGER,
            SameSite: 'strict'
        });
    }
}

export type LogInActionType = 'lastPlugin' | 'selectPlugin';
