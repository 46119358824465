import { FluentResource } from '@fluent/bundle';
import { makeStringIds } from './localization-string-util';

export enum PluginManagementStrings {
    btnAddPlugin_buttonText,
    btnCancelDeletePlugin_buttonText,
    btnCloseAddPluginModal_buttonText,
    btnConfirmDeletePlugin_buttonText,
    modalAddPlugin_title,
    msg_error_descriptionIsEmpty,
    msg_error_duplicatePlugin,
    msg_error_errorGettingPluginInstallStatus,
    msg_error_iconsAreMissing,
    msg_error_mainIsEmpty,
    msg_error_nameIsEmpty,
    msg_error_pluginConfigurationErrors,
    msg_error_pluginInstallStatusNotFound,
    msg_error_pluginInstallTimeout,
    msg_error_routeInUse,
    msg_error_routeIsEmpty,
    msg_error_uidIsEmpty,
    msg_error_uidIsNotProperFormat,
    msg_error_unknownErrorInstallingPlugin,
    msg_success_pluginInstalledSuccessfully,
    text_body_confirmDeletePlugin,
    text_body_loadingPlugins,
    text_body_noInstalledPlugins,
    text_body_pluginFileDropZoneLine1,
    text_body_pluginFileDropZoneLine2,
    text_header_plugins

}

export const pluginManagementStrings = makeStringIds<typeof PluginManagementStrings>('pluginManagement', PluginManagementStrings);

export function  getPluginManagementFluentResource(): FluentResource {
    // Translation can be done by assigning a string to each property
    // in the pluginManagementStrings object created above.
    return new FluentResource('');
}

