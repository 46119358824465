import { Service } from 'typedi';

@Service()
export class CookieService {

    public deleteCookie(name: string): void {
        document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:01 GMT`;
    }

    /**
     * The cookie value is not decoded by this function.
     * It is returned as-is.
     */
    public getCookie(name: string, options: GetCookieOptions | undefined): string | undefined {

        options = options ?? {};
        const makeLower = options.caseInsensitive === true;
        name = makeLower ? (name ?? '').toLowerCase() : name ?? '';

        const matchFn: (part: string) => boolean = makeLower
            ? part => (part ?? '').toLowerCase().trim().startsWith(name)
            : part => (part ?? '').trim().startsWith(name);

        return document.cookie
            .split(';')
            .find(matchFn)
            ?.trim()
            .split('=')?.[1];
    }

    /**
     * Note that the cookie value is not encoded
     * by this function.
     * It must contain only valid characters or
     * properly encoded before setting.
     */
    public setCookie(params: CookieParams): void {
        const parts: string[] = [
            `${params.Name}=${params.Value}`,
            `samesite=${params.SameSite}`
        ];

        if (params.SecondsToLive != null) {
            parts.push(`max-age=${params.SecondsToLive}`);
        }

        let path = params.Path?.trim() ?? '';
        if (path.length < 1) {
            path = '/';
        }
        parts.push(`path=${path}`);

        const cookieString = parts.join('; ');
        document.cookie = cookieString;
    }
}

export interface GetCookieOptions {
    caseInsensitive?: boolean;
}

export interface CookieParams {
    /**
     * Leave undefined for session cookie.
     */
    readonly SecondsToLive?: number ;
    readonly Name: string;
    readonly Path?: string;
    /**
     * lax: send the cookie for all same-site requests and top-level navigation GET requests
     * strict: prevent the cookie from being sent by the browser to the target site in all cross-site browsing context
     * none: no restrictions
     */
    readonly SameSite: 'lax' | 'strict' | 'none';
    readonly Value: string;
}
