import { Oauth2LoginLink } from 'cw-isdk/core';
import { useEffect } from 'react';
import { catchError, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import Container from 'typedi';
import { useRefStateObject } from '../../global-hooks/use-ref-state-object.hook';
import { useSymbol } from '../../global-hooks/use-symbol.hook';
import { LoggingService } from '../../logging.service';
import { LogInData } from './log-in.data';

const logger = Container.get(LoggingService);

export function useOauth2Clients(): UseOauth2ClientsState {

    const [state, setState] = useRefStateObject({
        loading: false,
        data: [],
        reload: () => nextReloadSymbol()
    });

    const [reloadSymbol, nextReloadSymbol] = useSymbol(Symbol());

    useEffect(() => {

        setState({ loading: true });

        const loginData = Container.get(LogInData);
        const subscription = loginData.oauth2Clients().pipe(
            tap(clients => {
                setState({ data: clients, loading: false });
            }),
            catchError(error => {
                // TODO
                logger.error(error);
                setState({ data: [], loading: false });
                return of();
            })
        ).subscribe();

        return () => {
            setState({ loading: false, data: [] });
            subscription.unsubscribe();
        };

    }, [reloadSymbol]);

    return state.current;
}

export interface UseOauth2ClientsState {
    data: Oauth2LoginLink[];
    loading: boolean;
    reload: () => void;
}
