import { AuthenticationServiceTypes } from 'cw-isdk/api-interface';
import { CWUser, PasswordRequirement } from 'cw-isdk/core';
import { Observable } from 'rxjs';
import { Service } from 'typedi';
import { ApiService } from './api.service';

@Service()
export class AuthenticationService {

    private readonly _apiService: ApiService;
    private readonly _area = 'General';
    private readonly _controller = 'Authentication';
    public constructor(
        apiService: ApiService
    ) {
        this._apiService = apiService;
    }

    public getPasswordPolicy(request: AuthenticationServiceTypes.Requests.PasswordPolicy): Observable<PasswordRequirement[]> {
        return this._apiService.get<AuthenticationServiceTypes.Requests.User, PasswordRequirement[]>('coreResponseValue', {
            action: 'PasswordPolicy',
            area: this._area,
            data: request,
            service: this._controller,
        });
    }

    public getUser(request: AuthenticationServiceTypes.Requests.User): Observable<CWUser> {
        return this._apiService.post<AuthenticationServiceTypes.Requests.User, CWUser>('coreResponseValue', {
            action: 'User',
            area: this._area,
            data: request,
            service: this._controller,
        });
    }
}
