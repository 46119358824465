const BASE_URL = window.environment.baseUrl;

export class UrlUtil {

    /**
     * This will preserve ALL leading slashes on parts[0],
     * and ALL trailing slashes on parts[last].
     * This means joinUrlParts('//foo', 'bar', 'baz///')) === "//foo/bar/baz///"
     * and joinUrlParts('//', '///')) === "//////"
     *
     */
    public static joinUrlParts(...parts: string[]): string {

        if (parts.length < 1) {
            return '';
        } else if (parts.length === 1) {
            return parts[0];
        }

        // Get all parts except the first part and the last part.
        // The first part and last part get processed differently.
        const middleParts = parts.slice(1, -1)
            .map(part =>
                // remove all leading and trailing slashes from middle parts.
                (part ?? '')
                    .replace(/^\/+/, '')
                    .replace(/\/+$/, '')
            )
            .filter(part => part.length > 0)
            .join('/');

        // First part may be like:
        // "/", "/foo/", "http://foo.com/", "//", "//foo"
        // We want to preserve all initial slashes and remove all
        // trailing slashes. So
        // 1. "/" -> "/",
        // 2. "/foo/" -> "/foo"
        // 3. "http://foo.com/" -> "http://foo.com"
        // 4. "//" -> "//"
        // 5: "//foo" -> "//foo"
        let firstPart = parts[0] ?? '';
        // Check to see if it's only slashes, e.g., "/", "//", "///", ...
        if (/^\/*$/.test(firstPart)) {
            // Do nothing
        } else {
            // Replace trailing slashes
            firstPart = firstPart.replace(/\/+$/, '');
        }

        // Last part may be like first part
        // except we want to keep trailing slashes and remove
        // leading slashes. So
        // 1. "/" -> "/",
        // 2. "/foo/" -> "foo/"
        // 3. "http://foo.com/" -> "http://foo.com/"
        // 4. "//" -> "//"
        // 5: "//foo" -> "//foo"

        let lastPart = parts[parts.length - 1] ?? '';
        // Check to see if it's only slashes, e.g., "/", "//", "///", ...
        if (/^\/*$/.test(lastPart)) {
            // Do nothing
        } else {
            // Replace leading slashes
            lastPart = lastPart.replace(/^\/+/, '');
        }

        if (middleParts.length > 0) {
            return `${firstPart}/${middleParts}/${lastPart}`;
        } else {
            return `${firstPart}/${lastPart}`;
        }
    }

    public static makeStandardApiUrl(baseUrl: URL, area: string, service: string, action: string): URL
    public static makeStandardApiUrl(area: string, service: string, action: string): URL
    public static makeStandardApiUrl(...args: unknown[]): URL {
        const area = args.length == 3 ? args[0] : args[1];
        const service = args.length == 3 ? args[1] : args[2];
        const action = args.length == 3 ? args[2] : args[3];

        let baseUrl = args.length == 3
            ? UrlUtil.makeUrl(BASE_URL, 'Services', {})
            : (args[0] as URL)

        return UrlUtil.makeUrl(baseUrl, `${area}/${service}/${action}`, undefined);
    }

    public static makeUrl(baseUrl: URL, path: string, query: Record<string, string> | undefined) {
        const url = new URL(UrlUtil.joinUrlParts(baseUrl.toString(), path));
        if (query != null && Object.keys(query).length > 0) {
            url.search = new URLSearchParams(query).toString();
        }
        return url;
    }
}
