import { FluentBundle, FluentResource } from '@fluent/bundle';
import { LocalizationProvider, ReactLocalization } from '@fluent/react';
import { Children, ReactNode, useEffect, useState } from 'react';
import { getPluginManagementFluentResource } from './localization/plugin-management.page';

interface AppLocalizationProviderProps {
    children: ReactNode;
}

export function AppLocalizationProvider(props: AppLocalizationProviderProps) {
    const [l10n, setL10n] = useState<ReactLocalization | null>(null);

    useEffect(() => {
        const bundle = new FluentBundle('en-us');
        bundle.addResource(new FluentResource((window as unknown as { environment: { whiteLabel: { terms: string } } }).environment.whiteLabel.terms));
        const resource = new FluentResource(`
login-heading = Log In
login-copyright = © 2009–{ $year } Azteca Systems, LLC
login-revision = Revision: { $version }+{ $buildNumber }
login-oauth-options = More ways to login:
login-link-reset-password = Forgot password
login-button-login = Login
login-button-login-loading = Logging in…
login-input-username =
  .label = Username
login-input-password =
  .label = Password
login-powered-by = Powered by
`);
        bundle.addResource(resource);
        bundle.addResource(getPluginManagementFluentResource());
        changeLocales([bundle]);
    }, []);

    async function changeLocales(bundles: FluentBundle[]) {
        setL10n(new ReactLocalization(bundles));
    }

    if (l10n === null) {
        return <div>Loading…</div>;
    }

    return (
        <>
            <LocalizationProvider l10n={l10n}>
                {Children.only(props.children)}
            </LocalizationProvider>
        </>
    );
}
