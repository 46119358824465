import { FluentResource } from '@fluent/bundle/esm/resource';

/**
 * Given a prefix of 'foo' and an input of { item1: 'string 1', item1: 'string 2' }
 * this will return new FluentResource('foo-item1 = string 1\r\nfoo-item2 = string 2');
 */
export function makeFluentResource<T>(prefix: string, input: Record<keyof T, string>): FluentResource {
    const strings = Object.entries(input).map(entry =>  `${prefix}-${entry[0]} = ${entry[1]}` );
    return  new FluentResource(strings.join('\r\n'));
}

/**
 * Given an prefix of 'foo' and an input of { item1: 'string 1', item1: 'string 2' }
 * this will return on object of { item1: 'foo-item1', item2: 'foo-item2'}
 */
export function makeStringIds<T>(prefix: string, input: T): Record<keyof T, string> {
    const retVal: Record<keyof T, string> = {} as Record<keyof T, string>;
    Object.keys(input).forEach(key =>  {
        if (!/\d+/.test(key)) {
            retVal[key as keyof T] = `${prefix}-${key}`;
        }
    });
    return retVal;
}
