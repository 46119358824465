import { CwIcon } from '@shared/ui/cw-icon.component';
import { If } from '@shared/ui/if.component';
import React, { useRef, useState } from 'react';
import css from './input.component.less';

export function Input(props: InputProps): React.ReactElement {

    const ref = useRef<HTMLInputElement>();
    const [showingPassword, updateShowingPassword] = useState(false);

    return (
        <label>{props.label}
            <div className={`${css.inputWrapper} ${props.className}`}>
                <input
                    autoComplete={props.autocomplete}
                    className={`${props.type === 'password' ? css.passwordInput : ''}`}
                    data-cw-id={props.cwId}
                    id={props.cwId}
                    name={props.cwId}
                    type={props.type}
                    ref={ref}
                    required={props.required}
                    value={props.value}
                    onInput={(event) => props.onChange(event.currentTarget.value)}
                    onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                            props.onEnter();
                        }
                    }} />
                <If
                    if={props.type === 'password'}
                    then={() => (
                        <button
                            className={css.showPassword}
                            type='button'
                            aria-label={showingPassword ? 'Hide password.' : 'Show password as plain text. Warning: this will display your password on the screen.'}
                            onClick={() => {
                                ref.current.type = showingPassword ? props.type : 'text';
                                updateShowingPassword(!showingPassword);
                            }}>
                            <CwIcon>{showingPassword ? 'hide' : 'show'}</CwIcon>
                        </button>
                    )} />
            </div>
        </label>
    );
}

export interface InputProps {
    autocomplete: string;
    className?: string;
    cwId?: string;
    label: string;
    type: 'text' | 'password';
    required?: boolean;
    value: string;
    onChange: (value: string) => void;
    onEnter: () => void;
}
