import { Observable } from 'rxjs';
import { Service } from 'typedi';
import { ResponseError } from './response-error';

@Service()
export class HttpService {

    /**
     * Returns the HTTP body as a string if status code is 200.
     * Throws if status code is not 200;
     * @throws
     */
    public  get(url: string, type: 'string',  options: GetOptions): Observable<string>;
    /**
     * Returns the HTTP body as parsed JSON if status code is 200.
     * Throws if status code is not 200;
     * @throws
     */
    public  get<TResponse>(url: string, type: 'json',  options: GetOptions): Observable<TResponse>;
    /**
     * Returns the full HTTP response. Does not check status code.
     * @throws
     */
    public  get(url: string, type: 'response',  options: GetOptions): Observable<Response>;
    public  get(url: string, type: string, options: GetOptions): Observable<unknown> {

        type ResponseType = 'string' | 'json' | 'response';

        return new Observable<unknown>(subscriber => {

            let existingUrlSearchParams: URLSearchParams;
            const qIndex = url.indexOf('?');
            if (qIndex > -1) {
                url = url.slice(0, qIndex);
                existingUrlSearchParams = new URLSearchParams(url.slice(qIndex+1));
            } else {
                existingUrlSearchParams = new URLSearchParams();
            }

            for (const [key, value] of (options.query ?? new URLSearchParams())) {
                existingUrlSearchParams.append(key, value);
            }

            // This will encode as needed. No need to call encodeURI().
            const query = existingUrlSearchParams.toString();
            if (query.length > 0) {
                url += '?' + query;
            }

            const abortToken = new AbortController();
            let canAbort = false;

            fetch(url, {
                method: 'get',
                headers: options.headers,
                credentials: 'include',
                signal: abortToken.signal
            })
                .then(response => {

                    const responseType = type as ResponseType;
                    if (responseType === 'json') {
                        if (response.status !== 200) {
                            throw new ResponseError(response);
                        }
                        return response.json();
                    } else if(responseType === 'response') {
                        return response;
                    } else if (responseType === 'string') {
                        if (response.status !== 200) {
                            throw new ResponseError(response);
                        }
                        return response.text();
                    } else {
                        throw new Error(`Unknown type: ${type}`);
                    }
                })
                .then(
                    value => {
                        subscriber.next(value);
                        canAbort = false;
                        subscriber.complete();
                    }, error => {
                        canAbort = false;
                        subscriber.error(error);
                    }
                );

            return () => {
                if (canAbort) {
                    abortToken.abort();
                }
            };
        });
    }

    public  post(url: string,  options: PostOptions): Observable<Response> {
        return new Observable<Response>(subscriber => {

            const abortToken = new AbortController();
            let canAbort = true;
            fetch(url, {
                body: options.body,
                method: 'post',
                headers: options.headers,
                credentials: 'include',
                signal: abortToken.signal
            }).then(response => {
                subscriber.next(response);
                canAbort = false;
                subscriber.complete();
            }, error => {
                canAbort = false;
                subscriber.error(error);
            });

            return () => {
                if (canAbort) {
                    abortToken.abort();
                }
            };
        });
    }

    public postUrlEncodedBody(url: string,  options: PostUrlEncodedBodyOptions): Observable<Response> {
        const headers = { ...(options.headers ?? {}) };
        headers['content-type'] = 'application/x-www-form-urlencoded';

        return this.post(url, {
            body: options.body.toString(),
            headers
        });
    }
}

export interface GetOptions {
    headers?: Record<string, string>;
    query: URLSearchParams;
}

export interface PostOptions {
    body?: string | ReadableStream<Uint8Array>;
    headers?: Record<string, string>;
}

export interface PostUrlEncodedBodyOptions {
    body: URLSearchParams;
    headers?: Record<string, string>;
}
