import React from 'react';
import { RootShell } from '../root-shell';
import css from './anonymous-shell.component.less';

/**
 * Shell for anonymous pages such as log-in/password-reset pages
 */
export function AnonymousShell(props: AnonymousShellProps): React.ReactElement {

    return (
        <RootShell title={props.title}>
            <div className={css.AnonymousShell}>
                <div className={css.gridContainer}>
                    <div className={css.gridHeaderRow}>
                        <div className={css.mapPin} />
                        <img className={css.logoImage} src='assets/logos/logo-blue.png' />
                    </div>
                    <div className={css.gridContentRow}>
                        <div className={css.centerWrapper}>
                            {props.render()}
                        </div>
                    </div>
                </div>
            </div>
        </RootShell>
    );
}

export interface AnonymousShellProps {
    title: string;
    render: () => React.ReactElement;
}
