import '@shared/global.less';
import React, { useEffect } from 'react';
import { AppLocalizationProvider } from './app-localization.provider';

export function RootShell(props: React.PropsWithChildren<RootShellProps>): React.ReactElement {

    useEffect(() => {
        document.title = props.title ?? 'Cityworks';
    }, [props.title]);

    return (
        <AppLocalizationProvider>
            <div className='RootShell'>
                {props.children}
            </div>
        </AppLocalizationProvider>
    );
}

export interface RootShellProps {
    title: string;
}
