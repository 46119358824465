import { ApiService } from '@shared/http/api.service';
import { HttpService } from '@shared/http/http.service';
import { CWUser, Oauth2LoginLink } from 'cw-isdk/core';
import { concatMap, from, map, Observable, of, switchMap } from 'rxjs';
import { Service } from 'typedi';

@Service()
export class LogInData {

    public constructor(
        private readonly _apiService: ApiService,
        private readonly _httpService: HttpService,
    ) { }

    public credentialLogin(username: string, password: string): Observable<LogInResponse> {

        return this._httpService.post('login?handler=credentialLogin', {
            body: JSON.stringify({ username, password }),
            headers: {
                'Content-Type': 'application/json'
            }
        }).pipe(
            concatMap(response => {
                if (response.status !== 200) {
                    return of({ LoggedIn: false, User: undefined });
                }
                return from(response.json()) as Observable<LogInResponse>;
            })
        );
    }

    public hideDefaultLogin(): Observable<boolean> {

        return this._apiService.post('httpResponse', {
            action: 'hideDefaultLogin',
            area: 'ams',
            data: {},
            service: 'preferences'
        }).pipe(
            switchMap(response => {

                if (response.status !== 200) {
                    return of([]);
                }

                return from(response.json());
            }),
            map((json: { Value: boolean } | undefined) => json?.Value ?? false)
        );
    }

    public oauth2Clients(): Observable<Oauth2LoginLink[]> {

        return this._apiService.post('httpResponse', {
            action: 'loginlinks',
            area: 'general',
            data: {},
            service: 'oauth2'
        }).pipe(
            switchMap(response => {

                if (response.status !== 200) {
                    return of([]);
                }

                return from(response.json());
            }),
            map((json: { Value: Oauth2LoginLink[] } | undefined) => json?.Value ?? [])
        );
    }
}

export interface LogInResponse {
    Error?: LoginError;
    LoggedIn: boolean;
    User: CWUser | undefined;
}

export enum LoginError {
    Unknown = 0,
    RequiresReset = 1,
    InvalidUserNameOrPassword = 2
}
