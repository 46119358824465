import { Service } from 'typedi';

@Service()
export class LoggingService {

    public error(error: unknown): void;
    public error(error: string): void;
    public error(error: Error): void;
    public error(...args: unknown[]): void {
        const arg0 = args[0];
        console.error(arg0);
    }
}
